.authFormContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.authForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 30%
}

.labelField {
    display: flex;
    flex-direction: column;
    width: 100%
}

.formField {
    height: 2rem;
    margin-bottom: 20px
}

.formButton {
    height: 2rem;
    background-color: steelblue;
    color: white;
    width: 100px;
}
label {
    font-weight: bold;
}