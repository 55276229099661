.formContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formHeading {
    text-align: center;
    margin-bottom: 20px;
}


.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
} 

.labelInput {
    width: 50%
}

.formInput {
    width: 100%;
    height: 2rem;
    margin-bottom: 20px;
}


.formLabel {
    display: flex;
    justify-content: flex-start;
    /* width:400px; */
    /* font-weight: bold ; */
    margin-bottom: 5px;
} 

.submitButton {
    width: 100px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin: 5px 0 20px 0
}

