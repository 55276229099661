.paperlistContainer {
    margin-bottom: 10px;
}

.title {
    font-weight: 700
}

.journal {
    text-transform: capitalize;
    font-style: italic;
    color: red
}

.sortBy {
    font-size: 1.1rem;
}

.labSortBtn {
    height: 1.6rem;
    width: 100px;
    border-radius: 5px;
    border: 1px solid grey;
    margin-left: 5px;
}

.btnContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top:10px;
    justify-content: center;
}

.displayModeHeading {
    margin-top:0
}

@media screen and (max-width: 400px) {
    .displayModeHeading {
        font-size: 1.1rem;
    }
}

.filterContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;
  }

.filterLabel {
    margin-right: 8px;
  }