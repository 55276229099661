.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bannerContainer {
  width: 100%;
  height: 100%;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center right;
  height: 400px;
  margin-top: 40px;
  position: relative;
}

#color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.9;
  z-index: -1;
}

.credit {
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
  margin-top: 0;
  margin-bottom: 0;
}

.emailSpan {
  font-weight: bold;
}
.creditContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 0px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.fetchingText {
  color: steelblue;
  text-align: center;
  margin-bottom: 0;
}

.bannerTitle {
  font-size: 8rem;
  color: white;
  font-family: "Play", sans-serif;
  font-weight: 500;
  z-index: 5;
}

@media screen and (max-width: 500px) {
  .bannerTitle {
    font-size: 6rem;
  }
}

@media screen and (max-width: 400px) {
  .classicFilterHead {
    font-size: 1.1rem;
  }
}

.pageHeading {
  margin-top: 50px;
  margin-bottom: 0;
}

.pathwayHeading {
  margin-top: 0px;
}

.pageBlurb {
  text-align: justify;
  margin-bottom: 0;
}

.filterBlurb {
  text-align: justify;
  margin-bottom: 10px;
}

.paperFilterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

.labCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 10px 0 0 0;
  font-size: 0.85rem;
}

.datePlusHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.yearHeading {
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 1.1rem;
  color: steelblue;
}

.pubmedLink {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.pathwayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.labsContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.labCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid grey;
  width: 100%;
  margin-bottom: 20px;
  padding: 7px;
  -webkit-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  -moz-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  border-radius: 7px;
  padding: 15px;
}

.labCardElements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin: 5px; */
}

.labCardHeading {
  font-weight: bold;
  margin: 5px 0;
  font-size: 1.1rem;
  /* margin:0 */
}

.labCardLabel {
  margin: 2px 0;
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  justify-content: center;
}

.labSortBtn {
  height: 2rem;
  width: 80px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-left: 5px;
}

.paperSortBtn {
  height: 2rem;
  width: 100px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-left: 5px;
}

.sortLabel {
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 400px) {
  .sortLabel {
    font-size: 1rem;
  }
}

.countryLabel {
  color: steelblue;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0px;
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterHeading {
  margin-right: 10px;
}

.classicsBtn {
  width: 150px;
  height: 2rem;
  border-radius: 5px;
  background-color: white;
  color: black;
  border: 1px solid black;
}

@media screen and (max-width: 400px) {
  .classicsBtn {
    margin-bottom: 5px;
  }
}

.classicsBtn:hover {
  background-color: black;
  color: white;
  border: none;
}

.dropDown {
  height: 2rem;
  margin-right: 10px;
  border-radius: 5px;
}

.filterReset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventItem {
  font-size: 0.9rem;
  margin: 0;
}

.timeline {
  z-index: -1;
}

.allResources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.resourceContainer {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 0;
  -webkit-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  -moz-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  border-radius: 7px;
}

.resourceHeading {
  margin-bottom: 5px;
  color: steelblue;
}

.resource {
  margin: 5px 15px 5px 15px;
}

.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
}

.itemTitle {
  font-weight: bold;
  font-size: 1.1rem;
}

@media screen and (max-width: 400px) {
  .itemTitle {
    font-size: 1rem;
  }
}

.resourceItem {
  display: flex;
  margin: 0;
  font-size: 0.9rem;
}

.resourceItem h4 {
  margin: 2px;
}

.resourceItem p {
  margin: 2px;
  text-align: justify;
}

.resourceItem a {
  margin: 2px;
}

.resourceItem p {
  margin: 3px;
}

.resourceRuler {
  border: 1px solid lightgrey;
  margin-bottom: 0;
}

.platformContainer {
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.sectionHeading {
  color: steelblue;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.bookImage {
  height: 100px;
  width: auto;
  margin-top: 10px
}

.imageTitle {
  display: flex;
  align-items: center;
}