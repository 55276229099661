.pathwayContainer {
  margin: 50px;
  font-size: 0.9rem;
  width: 80%
}
@media screen and (max-width: 400px){
  .pathwayContainer {
    width: 90%;
    text-align: justify;
  }
}
@media screen and (max-width: 769px){
  .pathwayContainer {
    width: 90%;
    text-align: justify;
  }
}

@media screen and (max-width: 400px){
  .pathwayHeading {
    text-align: center;
  }
}

.figureLegend_long {
  text-align: justify;
  width: 90%
}
@media screen and (max-width: 400px){
  .figureLegend_long {
  width: 100%
  }
}

.figureLegend_short{
  text-align: justify;
  width: 90%
}

@media screen and (max-width: 400px){
  .figureLegend_short {
  width: 100%;
  margin-top: 0;
  }
}
@media screen and (max-width: 769px){
  .figureLegend_short {
  width: 90%;
  text-align: justify;
  margin-top: 0;
  }
}

p {
  margin-bottom: 25px
}


@media screen and (max-width: 400px){
  .pathwayContainer {
    width: 90%;
  },
  .figureLegend_long {
    width: 100%;
  },
    .figureLegend_short {
      text-align: center;
      width: 50%
    },
    p {
      margin-bottom: 0
    }
  }


.pathwayFig1 {
  width: 60%;
  margin-bottom: 20px;

}

@media screen and (max-width: 400px){
  .pathwayFig1 {
    width: 100%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 762px){
  .pathwayFig1 {
    width: 100%;
    margin-bottom: 5px;
  }
}

.pathwayFig2 {
  width:80%;
  margin-bottom: 20px;

}

@media screen and (max-width: 400px){
  .pathwayFig2 {
    width: 100%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 769px){
  .pathwayFig2 {
    width: 90%;
    margin-bottom: 5px;
  }
}

.pathwayFig3 {
  width: 80%;
  margin-bottom: 5px;
}

@media screen and (max-width: 400px){
  .pathwayFig3
   {
    width: 100%;
  }
}

@media screen and (max-width: 769px){
  .pathwayFig3 {
    width: 90%;
    margin-bottom: 5px;
  }
}

.pathwayFig4 {
  width: 80%;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px){
  .pathwayFig4
   {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 769px){
  .pathwayFig4 {
    width: 90%;
    margin-bottom: 5px;
  }
}


.pathwayFig5 {
  width: 80%;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px){
  .pathwayFig5
   {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 769px){
  .pathwayFig5 {
    width: 90%;
    margin-bottom: 5px;
  }
}

.pathwayFig6{
    width: 70%;
    margin-bottom: 20px;

}

@media screen and (max-width: 400px){
.pathwayFig6 {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 769px){
    .pathwayFig6 {
      width: 80%;
      margin-bottom: 5px;
    }
    }

.figureTitle {
  font-weight: bold;
} 

.sectionHeading {
  margin-bottom: 5px;
}

.section {
  margin-top: 0;
}

