.nav {
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    background-color: rgb(0,0,0);
    position: fixed;
    top: 0;
    width: 100%;
    font-family:'Roboto Condensed', sans-serif;
    z-index: 100
}

.link{
    text-decoration: none;
    color: white;
    margin: 0 20px 
}

.link:hover{
    color: red;
}

.link:active{
    color: black
}

.nav {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    background-color: rgb(0,0,0);
    position: fixed;
    top: 0;
    width: 100%;
    font-family:'Roboto Condensed', sans-serif;
}

.link{
    text-decoration: none;
    color: white;
    margin: 0 20px 
}

.link:active{
    color: black
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 40px;
    height: 30px;
    left: 20px;
    top: 5px;
  }


  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
    height: 10%
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: rgb(247, 194, 240);
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-right: 5px
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    margin-top: 25px
  }
  
  /* General sidebar styles */
  .bm-menu {
    background:rgb(232, 233, 235);
    padding: 2em 1.5em 0;
    font-size: 1.5em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  /* .bm-morph-shape {
    fill: #373a47;
  } */
  
  /* Wrapper for item list */
  .bm-item-list {
    color: green;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    margin-bottom: 10px;
    color:black;
    text-decoration: none;
  }
  .bm-item:hover {
    color: white;
    text-decoration: none;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: 25px;
  }

